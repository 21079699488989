import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";

import { loadEnvironment } from "Reducers/environment";
import { getOrganizationDescriptionIdFromProject } from "Libs/utils";

const PageLayout = styled.div`
  height: 100%;
  width: 100%;
`;

class Environment extends React.Component {
  componentDidMount() {
    this.props.loadEnvironment();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.currentProjectOrganization &&
      (this.props.params.environmentId !== nextProps.params.environmentId ||
        this.props.currentProjectOrganization !==
          nextProps.currentProjectOrganization)
    ) {
      this.props.loadEnvironment(
        nextProps.currentProjectOrganization,
        nextProps.params.environmentId
      );
    }
  }

  render() {
    return <PageLayout>{this.props.children}</PageLayout>;
  }
}

Environment.propTypes = {
  params: PropTypes.shape({
    organizationId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired
  }),
  children: PropTypes.node.isRequired,
  loadEnvironment: PropTypes.func,
  currentProjectOrganization: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const projectState = state.project || new Map();
  const project = projectState.getIn([
    "data",
    props.params.organizationId,
    props.params.projectId
  ]);

  return {
    currentProjectOrganization:
      project && getOrganizationDescriptionIdFromProject(project)
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  loadEnvironment: (
    currentProjectOrganization = props.params.organizationId,
    currentEnvironmentId = props.params.environmentId
  ) =>
    dispatch(
      loadEnvironment(
        currentEnvironmentId,
        props.params.projectId,
        currentProjectOrganization
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Environment);
